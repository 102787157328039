<form  class="contact" [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
	<mat-form-field>
		<mat-label>Nombre</mat-label>
		<input  matInput [formControl]="nombre" required>
		<mat-error *ngIf="nombre.invalid">Ingresa tu nombre</mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-label>Correo electrónico</mat-label>
		<input matInput [formControl]="correo_electronico" placeholder="email@example.com" required>
		<mat-error *ngIf="correo_electronico.invalid">Ingresa un correo valido</mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-label>Teléfono</mat-label>
		<input type="tel" matInput [formControl]="telefono" maxlength="10" required>
		<mat-hint align="end"> {{telefono.value?.length || 0}}/10</mat-hint>
		<mat-error *ngIf="telefono.invalid">Ingresa tu teléfono</mat-error>
	</mat-form-field>
	<button [disabled]="isLoading" class="submit" type="submit">Enviar</button>
	<input [formControl]="honeypot" class="hidden" type="text" />
	<div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text">
		<span>{{responseMessage}}</span>
	</div>
</form>
