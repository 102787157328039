import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

	form: FormGroup;
	nombre: FormControl = new FormControl("", [Validators.required]);
	correo_electronico: FormControl = new FormControl("", [Validators.required, Validators.email]);
	telefono: FormControl = new FormControl("", [Validators.required, Validators.maxLength(10),Validators.pattern(/^[1-9]\d{8,10}$/)]);
	honeypot: FormControl = new FormControl(""); // we will use this to prevent spam
	submitted: boolean = false; // show and hide the success message
	isLoading: boolean = false; // disable the submit button if we're loading
	responseMessage: string; // the response message to show to the user
	constructor(private formBuilder: FormBuilder, private http: HttpClient) {
		this.form = this.formBuilder.group({
			nombre: this.nombre,
			correo_electronico: this.correo_electronico,
			telefono: this.telefono,
			honeypot: this.honeypot
		});
	}
	ngOnInit(): void {
	}
	onSubmit() {
		if (this.form.status == "VALID" && this.honeypot.value == "") {
			this.form.disable(); // disable the form if it's valid to disable multiple submissions
			var formData: any = new FormData();
			formData.append("Nombre", this.form.get("nombre").value);
			formData.append("Correo electrónico", this.form.get("correo_electronico").value);
			formData.append("Teléfono", this.form.get("telefono").value);
			this.isLoading = true; // sending the post request async so it's in progress
			this.submitted = false; // hide the response message on multiple submits
			this.http.post("https://script.google.com/macros/s/AKfycbzqPWaH1M-efyA-ShKyDChWWzzg6z_zdIbcbBh0Vw/exec", formData).subscribe(
				(response) => {
					// choose the response message
					if (response["result"] == "success") {
						this.responseMessage = "¡Gracias!";
					} else {
						this.responseMessage = "Parece que algo salió mal, intenta recargar tla pagina";
					}
					this.form.enable(); // re enable the form after a success
					this.submitted = true; // show the response message
					this.isLoading = false; // re enable the submit button
					console.log(response);
					//alert('¡Gracias seras redirigido!');
					var win = window.open('https://teran1165.com/teranbrochure.pdf' , '_blank');//Require quatations
					window.close();
				},
				(error) => {
					this.responseMessage = "Parece que algo salió mal, intenta recargar tla pagina..";
					this.form.enable(); // re enable the form after a success
					this.submitted = true; // show the response message
					this.isLoading = false; // re enable the submit button
					console.log(error);
				}
			);
		}
	}
}
